import React, { useState, useEffect } from "react";
import { Modal, Button, FormGroup } from "react-bootstrap";
import axios from "axios";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Field from "../../../utils/Form/Widgets/Field";
import Select from "../../../utils/Form/Widgets/Select";

// Props türünü tanımlayalım
type Props = {
  onHide: () => void;
  show: boolean;
  refreshFunction?: () => void;
  defaultPropertyValue?: string;
  dateInfo?: { start?: Date; end?: Date };
};

const PricesAddModal: React.FC<Props> = ({
  show,
  onHide,
  refreshFunction,
  defaultPropertyValue,
  dateInfo,
}) => {
  const formatDate = (date: Date | undefined) => {
    if (!date) return "";
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState({
    property: defaultPropertyValue || "",
    start_date: formatDate(dateInfo?.start),
    end_date: formatDate(dateInfo?.end),
    price: "",
    discounted_price: "",
    min_duration_date: "",
    cleaning_service_price: "",
    partial_paid_rate: "",
    status: "1",
  });

  const [formBuildId, setFormBuildId] = useState("");
  const [formId, setFormId] = useState("");
  const [formToken, setFormToken] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    // Form tokenlarını almak için API çağrısı
    const fetchFormTokens = async () => {
      try {
        const response = await axios.get("/prices/save/add");
        setFormBuildId(response.data.data.form_build_id);
        setFormId(response.data.data.form_id);
        setFormToken(response.data.data.form_token);
        setToken(response.data.data.token);
      } catch (error) {
        console.error("Form tokenlarını alırken hata oluştu:", error);
      }
    };

    fetchFormTokens();
  }, []);

  const pricesStatus = [
    { value: "0", label: "Pasif" },
    { value: "1", label: "Aktif" },
  ];

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      // Form verilerini gönderme işlemi
      const postData = {
        prices: { ...formData },
        form_id: formId,
        form_build_id: formBuildId,
        form_token: formToken,
      };

      const response = await axios.post("/prices/save/add", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Form başarıyla gönderildi:", response.data); // Başarılı isteği loglayalım

      if (refreshFunction) {
        refreshFunction();
      }
      onHide();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios hatası:", error.response?.data || error.message);
        alert(
          "Fiyat detaylarını kaydederken hata oluştu: " +
            (error.response?.data?.message || error.message)
        );
      } else {
        console.error("Bilinmeyen hata:", error);
        alert("Fiyat detaylarını kaydederken bilinmeyen bir hata oluştu.");
      }
    }
  };

  return (
    <Modal
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered"
      size="lg"
      show={show}
      backdrop={true}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Fiyatlandırma Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <AutocompleteSelect
              title="Mülk"
              name="property"
              onChange={handleInputChange}
              defaultValue={formData.property}
              multiple={false}
              autoCompleteTokenUrl="/properties/autocomplete"
            />

            <Field
              title="Giriş Tarihi"
              className="form-control"
              type="date"
              name="start_date"
              onChange={handleInputChange}
              value={formData.start_date}
            />
            <Field
              title="Çıkış Tarihi"
              className="form-control"
              type="date"
              name="end_date"
              onChange={handleInputChange}
              value={formData.end_date}
            />

            <Field
              title="Fiyat"
              className="form-control"
              type="text"
              name="price"
              onChange={handleInputChange}
              value={formData.price}
            />

            <Field
              title="İndirimli Fiyat"
              className="form-control"
              type="text"
              name="discounted_price"
              onChange={handleInputChange}
              value={formData.discounted_price}
            />

            <Field
              title="Minimum Konaklama Gün Sayısı"
              className="form-control"
              type="text"
              name="min_duration_date"
              onChange={handleInputChange}
              value={formData.min_duration_date}
            />

            <Field
              title="Temizlik Ücreti"
              className="form-control"
              type="text"
              name="cleaning_service_price"
              onChange={handleInputChange}
              value={formData.cleaning_service_price}
            />
            <Field
              title="Ön Ödeme Oranı (%)"
              className="form-control"
              type="text"
              name="partial_paid_rate"
              onChange={handleInputChange}
              value={formData.partial_paid_rate}
            />
            <Select
              title="Fiyatlandırma Durumu"
              name="status"
              defaultValue={formData.status}
              options={pricesStatus}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button type="submit" className="btn btn-success btn-sm">
            <span className="fa fa-floppy-save"></span> Kaydet
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PricesAddModal;
