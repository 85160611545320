import axios, { AxiosResponse } from "axios";
import { SelectListItem } from "./_models";
import { WithData } from "../../../utils/axiosWithCSRF";

export interface Property {
  name: string;
  type: string;
  description_title: string;
  description: string;
  status: string;
  region: string;
  location: string;
  capacity: string;
  users: string[];
  categories: string[];
  features: string[];
  property_images: string[];
}

export interface PropertyFormKeys {
  form_id: string;
  form_build_id: string;
  form_token: string;
  token: string;
}

export interface PropertyPostData {
  properties: {
    name: string;
    type: string;
    description_title: string;
    description: string;
    status: string;
    region: string;
    location: string;
    capacity: string;
    users: string[];
    categories: string[];
    features: string[];
    property_images: string[];
  };
  form_id: string;
  form_build_id: string;
  form_token: string;
}

export function getPropertyFromId(propertyId: string = 'add'): Promise<Property> {
  const url = `/properties/single/${propertyId}`;
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: Property }>) => response.data.data);
}

export function getCategoriesOptionsList(): Promise<SelectListItem[]> {
  const url = `/categories/getCategoryList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getFeaturesOptionsList(): Promise<SelectListItem[]> {
  const url = `/features/getFeaturesList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getUsersOptionsList(): Promise<SelectListItem[]> {
  const url = `/getUsersList`;
  return axios
    .get(url)
    .then(
      (response: AxiosResponse<WithData<{ value: string; label: string }[]>>) =>
        response.data
    )
    .then((response) =>
      response.data.map((a) => ({
        label: a.label,
        value: a.value,
      }))
    );
}

export function getPropertyImageUploadKey(): Promise<string> {
  const url = `/properties/getPropertyImageUploadKey`;
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: string }>) => response.data.data);
}

export function getPropertyFormKeys(propertyId: string = 'add'): Promise<PropertyFormKeys> {
  const url = `/properties/save/${propertyId}`;
  return axios
    .get(url)
    .then((response: AxiosResponse<{ data: PropertyFormKeys }>) => response.data.data);
}

export function postPropertyForm(propertyId: string = 'add', data: PropertyPostData): Promise<any> {
  const url = `/properties/save/${propertyId}`;
  return axios
    .post(url, data)
    .then((response: AxiosResponse<{ data: string }>) => response.data.data);
}
