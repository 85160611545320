/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { useAuth } from '../modules/auth/core/AuthProvider'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { loggedIn } = useAuth()
  return (
    <Routes>
      <Route path='logout' element={<Logout />} />
      {loggedIn ? (
        <>
          <Route path='*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to='/dashboard-1' />} />
        </>
      ) : (
        <>
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/auth' />} />
        </>
      )}
    </Routes>
  )
}

export { AppRoutes }
