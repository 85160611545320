import React from "react";
import { Row, Col } from "react-bootstrap";
import StatisticsWidget from "../../../../components/StatisticsWidget";
import { useTranslation } from "react-i18next";

function SummaryCards() {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="primary"
            description={t("Reservations")}
            stats="250"
            icon="fe-calendar"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="success"
            description={t("Property Count")}
            stats="600"
            icon="fe-home"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="info"
            description={t("Customer Count")}
            stats="122"
            icon="fe-users"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="warning"
            description={t("Vilbox Website Views")}
            stats="12500"
            icon="fe-eye"
          />
        </Col>
      </Row>
    </>
  );
}

export default SummaryCards;
