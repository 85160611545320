import React, { useEffect, useState } from "react";
import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { PricingList, PricingData } from "../core/_model";
import { getPricingList } from "../core/_request";

interface CalendarProps {
  onDateClick: (value: any) => void;
  onEventClick: (value: any) => void;
  onDrop: (value: any) => void;
  onEventDrop: (value: any) => void;
  onSelect: (start: Date, end: Date) => void;
  property: string;
}

const PricingCalendar = ({
  onDateClick,
  onEventClick,
  onDrop,
  onEventDrop,
  onSelect,
  property,
}: CalendarProps) => {
  const handleDateClick = (arg: any) => {
    onDateClick(arg);
  };
  const handleEventClick = (arg: any) => {
    onEventClick(arg);
  };
  const handleDrop = (arg: any) => {
    onDrop(arg);
  };
  const handleEventDrop = (arg: any) => {
    onEventDrop(arg);
  };
  const handleSelect = (arg: any) => {
    onSelect(arg.start, arg.end);
  };

  const [events, setEvent] = useState<PricingList>([]);
  const fetchPricingData = async (property: string, start: Date, end: Date) => {
    try {
      const data = await getPricingList(
        property,
        start.toISOString(),
        end.toISOString()
      );
      setEvent(data);
    } catch (error) {
      console.log("Error fetching pricing data:", error);
    }
  };
  const handleDatesSet = (arg: any) => {
    const startDate = arg.start;
    const endDate = arg.end;
    if (property) {
      fetchPricingData(property, startDate, endDate);
    }
  };

  useEffect(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    if (property) {
      fetchPricingData(property, firstDayOfMonth, lastDayOfMonth);
    }
  }, [property]);

  // Define calendar options
  const calendarOptions: CalendarOptions = {
    initialView: "dayGridMonth",
    plugins: [dayGridPlugin, interactionPlugin, BootstrapTheme],
    handleWindowResize: true,
    themeSystem: "bootstrap",
    buttonText: {
      today: "Bugün",
      month: "Ay",
      prev: "Önceki Ay",
      next: "Sonraki Ay",
    },
    headerToolbar: {
      left: "prev today",
      center: "title",
      right: "next",
    },
    editable: true,
    selectable: true,
    droppable: true,
    events: events?.map((price: any) => ({
      id: price.daily_price_id,
      title: "Gecelik Ücret: "+price.price + " TL",
      className: price.className,
      price: price.price,
      discountPrice: price.discountPrice,
      start: price.date,
      end: price.date,
      display: "background",
      allDay: true,
      color: "#009933",
      textColor: "#0000",
    })),
    dateClick: handleDateClick,
    eventClick: handleEventClick,
    drop: handleDrop,
    eventDrop: handleEventDrop,
    select: handleSelect,
    datesSet: handleDatesSet,
    height: 600,
  };

  return (
    <>
      {/* FullCalendar component */}
      <div id="calendar">
        <FullCalendar {...calendarOptions} />
      </div>
    </>
  );
};

export default PricingCalendar;
