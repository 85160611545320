import { lazy, FC, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { meApi } from "../modules/auth/core/_requests";
import { User } from "../modules/auth/core/_models";
import VerticalLayout from "../../layouts/Vertical";
import { Provider } from "react-redux";
import { configureStore } from "../../redux/store";
import PropertiesPage from "../modules/properties/PropertiesPage";
import CategoryPage from "../modules/category/CategoryPage";
import ReservationsPage from "../modules/reservations/ReservationsPage";
import FeaturesPage from "../modules/features/FeaturesPage";
import CustomersPage from "../modules/customers/CustomersPage";
import Dashboard from "../modules/dashboard/Dashboard";
import UsersPage from "../modules/users/UsersPage";
import PricesPage from "../modules/prices/PricesPage";
import RegionsPage from "../modules/regions/RegionsPage";

const PrivateRoutes = () => {
  //const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem("@auth/token") ?? undefined;
  });

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token); // meApi'yi çağırarak kullanıcı bilgilerini alıyoruz

      if (!user) {
        setToken(undefined);
        return;
      }
      setUser(user.data);
    };
    fetchUser();
  }, []);
  let hasValidRole = false;
  user?.roles.map((role) => {
    if (role == "1" || role == "2") {
      hasValidRole = true;
    } else {
      hasValidRole = false;
    }
  });

  if (!hasValidRole) {
    return (
      <Provider store={configureStore({})}>
        <Routes>
          <Route element={<VerticalLayout />}>
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Provider>
    );
  } else {
    return (
      <Provider store={configureStore({})}>
        <Routes>
          <Route element={<VerticalLayout />}>
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="properties/*" element={<PropertiesPage />} />
            <Route path="categories/*" element={<CategoryPage />} />
            <Route path="features/*" element={<FeaturesPage />} />
            <Route path="reservations/*" element={<ReservationsPage />} />
            <Route path="prices/*" element={<PricesPage />} />
            <Route path="customers/*" element={<CustomersPage />} />
            <Route path="users/*" element={<UsersPage />} />
            <Route path="regions/*" element={<RegionsPage />} />
          </Route>
        </Routes>
      </Provider>
    );
  }
};

export { PrivateRoutes };
