import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  FormLabel,
  Button,
  FormCheck,
} from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";

// styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileUploader from "../../../utils/FileUploader";
import { SelectListItem } from "../core/_models";
import {
  getPropertyFromId,
  getCategoriesOptionsList,
  getFeaturesOptionsList,
  getUsersOptionsList,
  getPropertyImageUploadKey,
  getPropertyFormKeys,
  postPropertyForm,
  PropertyPostData,
  PropertyFormKeys,
  Property,
} from "../core/_requests";
import { useParams } from "react-router-dom";

function PropertyEdit() {
  const { secrReportId } = useParams();

  const [categoriesOptionsList, setCategoriesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [featuresOptionsList, setFeaturesOptionsList] = useState<
    SelectListItem[]
  >([]);
  const [usersOptionsList, setUsersOptionsList] = useState<SelectListItem[]>(
    []
  );
  const [propertyFormKeys, setPropertyFormKeys] = useState<PropertyFormKeys>();
  const [imageUploadKey, setImageUploadKey] = useState<string>();
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [location, setLocation] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCapacity, setSelectedCapacity] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [descriptionTitle, setDescriptionTitle] = useState("");
  const [initialFiles, setInitialFiles] = useState<string[]>([]);
  //const { contentBlocks, entityMap } = convertFromHTML('');
  /*const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    )
  );*/
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  useEffect(() => {
    getPropertyFromId(secrReportId).then(setFieldValues);
    getCategoriesOptionsList().then(setCategoriesOptionsList);
    getFeaturesOptionsList().then(setFeaturesOptionsList);
    getUsersOptionsList().then(setUsersOptionsList);
    getPropertyFormKeys().then(setPropertyFormKeys);
    getPropertyImageUploadKey().then(setImageUploadKey);
  }, []);

  const isMobile = window.innerWidth < 990;

  const propertyTypes = [
    { value: "villa", label: "Villa" },
    { value: "hotel", label: "Otel" },
    { value: "pansion", label: "Pansiyon" },
  ];

  const propertyRegions = [
    { value: "kas", label: "Kaş" },
    { value: "kalkan", label: "Kalkan" },
    { value: "fethiye", label: "Fethiye" },
    { value: "sapanca", label: "Sapanca" },
  ];

  const propertyCapacities = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "0", label: "10+" },
  ];

  const propertyStatus = [
    { value: "1", label: "Active" },
    { value: "2", label: "Passive" },
  ];

  const setFieldValues = (values: Property) => {
    setName(values.name);
    setSelectedOwner("1");
    setSelectedType(values.type);
    setLocation(values.location);
    setSelectedRegion(values.region);
    setSelectedCapacity(values.capacity);
    setSelectedStatus(values.status);
    setSelectedFeatures(values.features);
    setSelectedCategories(values.categories);
    setDescriptionTitle(values.description_title);
    const initialFiles = [{ 0: "abc.jpg" }];
  };

  const handleNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(event.target.value);
  };

  const handleOwnerChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOwner(event.target.value);
  };

  const handleTypeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedType(event.target.value);
  };

  const handleLocationChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLocation(event.target.value);
  };

  const handleRegionChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedRegion(event.target.value);
  };

  const handleCapacityChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedCapacity(event.target.value);
  };

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedFeatures([...selectedFeatures, value]);
    } else {
      setSelectedFeatures(
        selectedFeatures.filter((feature) => feature !== value)
      );
    }
  };

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedCategories([...selectedCategories, value]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((category) => category !== value)
      );
    }
  };

  const handleStatusChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedStatus(event.target.value);
  };

  const handleDescriptionTitleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescriptionTitle(event.target.value);
  };

  const handleImageChange = (status: any, meta: any, files: any) => {
    /*console.log("status: ", status);
    console.log("meta: ", meta);
    console.log("files: ", files);*/
    /*if (meta) {
      setSelectedImages([...selectedImages, meta.name]);
    } else {
      setSelectedImages(selectedImages.filter((image) => image !== meta.name));
    }*/
  };

  const handleImageSubmit = (images: any) => {
    setSelectedImages(images);
  };

  const handleSubmit = (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      console.log(propertyFormKeys);
      console.log(propertyFormKeys?.form_token);
      console.log(name);
      console.log(selectedOwner);
      console.log(selectedType);
      console.log(location);
      console.log(selectedRegion);
      console.log(selectedCapacity);
      console.log(selectedFeatures);
      console.log(selectedCategories);
      console.log(selectedStatus);
      console.log(descriptionTitle);
      //console.log(editorState);
      console.log(selectedImages);

      const data: PropertyPostData = {
        properties: {
          name: name,
          type: selectedType,
          description_title: descriptionTitle,
          description:
            "Villa Zeytin Fethiye'nin harika bir konumunda yer alan doğa dostu bir villadır.",
          status: selectedStatus,
          region: selectedRegion,
          location: location,
          capacity: selectedCapacity,
          users: [selectedOwner],
          categories: selectedCategories,
          features: selectedFeatures,
          property_images: selectedImages,
        },
        form_id: propertyFormKeys ? propertyFormKeys.form_id || "" : "",
        form_build_id: propertyFormKeys
          ? propertyFormKeys.form_build_id || ""
          : "",
        form_token: propertyFormKeys ? propertyFormKeys.form_token || "" : "",
      };
      postPropertyForm(secrReportId, data).then((result) => {
        if (result.success) {
          console.log(result.entity_id);
        }
      });
    }

    setValidated(true);
  };

  return (
    <>
      <Form
        method="POST"
        action="deneme"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col lg={6}>
            <Row
              style={{
                height: "100%",
                ...(isMobile ? {} : { marginRight: "10px" }),
              }}
            >
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                  </h5>
                  <FormGroup className="mb-3" controlId="nameValidation">
                    <FormLabel>Property Name</FormLabel>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Property Name"
                      required={true}
                      onChange={handleNameChange}
                      value={name}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter property name.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3" controlId="ownerValidation">
                    <FormLabel>Property Owner</FormLabel>
                    <Form.Control
                      as="select"
                      name="owner"
                      placeholder="Select Property Owner"
                      className="react-select react-select-container"
                      prefix="react-select"
                      multiple={false}
                      required={true}
                      onChange={handleOwnerChange}
                      value={selectedOwner}
                    >
                      <option value="">Select Property Owner</option>
                      {usersOptionsList.map((propOwner) => (
                        <option key={propOwner.value} value={propOwner.value}>
                          {propOwner.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select an owner.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Property Type</FormLabel>
                    <Form.Control
                      as="select"
                      name="type"
                      placeholder="Select Property Type"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <option value="">Select Property Type</option>
                      {propertyTypes.map((propType) => (
                        <option key={propType.value} value={propType.value}>
                          {propType.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property type.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3" controlId="locationValidation">
                    <FormLabel>Property Location</FormLabel>
                    <Form.Control
                      type="text"
                      name="location"
                      placeholder="Property Location"
                      required={true}
                      onChange={handleLocationChange}
                      value={location}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter property location.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Property Region</FormLabel>
                    <Form.Control
                      as="select"
                      name="region"
                      placeholder="Select Property Region"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleRegionChange}
                      value={selectedRegion}
                    >
                      <option value="">Select Property Region</option>
                      {propertyRegions.map((propRegion) => (
                        <option key={propRegion.value} value={propRegion.value}>
                          {propRegion.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property region.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel>Property Capacity</FormLabel>
                    <Form.Control
                      as="select"
                      name="capacity"
                      placeholder="Select Property Capacity"
                      className="react-select react-select-container"
                      prefix="react-select"
                      required={true}
                      onChange={handleCapacityChange}
                      value={selectedCapacity}
                    >
                      <option value="">Select Property Capacity</option>
                      {propertyCapacities.map((propCapacity) => (
                        <option
                          key={propCapacity.value}
                          value={propCapacity.value}
                        >
                          {propCapacity.label}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select property capacity.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <FormLabel className="mb-2">Property Status</FormLabel>
                    <div className="">
                      {propertyStatus.map((propStatus) => (
                        <div key={propStatus.value}>
                          <Form.Check
                            type="radio"
                            id={propStatus.value}
                            name="status"
                            value={propStatus.value}
                            className="me-1"
                            label={propStatus.label}
                            required={true}
                            feedback="Please select property status."
                            feedbackType="invalid"
                            checked={"" + selectedStatus === propStatus.value}
                            onChange={handleStatusChange}
                          />
                        </div>
                      ))}
                    </div>
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Property Features
                  </h5>
                  <div
                    className="features"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      maxHeight: "100px",
                    }}
                  >
                    {featuresOptionsList.map((propFeature) => (
                      <div>
                        <FormCheck
                          multiple={true}
                          type="checkbox"
                          id={propFeature.value}
                          name="features"
                          className="me-1"
                          value={propFeature.value}
                          label={propFeature.label}
                          onChange={handleFeatureChange}
                          checked={selectedFeatures.includes(propFeature.value)}
                        />
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Property Categories
                  </h5>
                  <div
                    className="category"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      maxHeight: "100px",
                    }}
                  >
                    {categoriesOptionsList.map((propCategory) => (
                      <div>
                        <FormCheck
                          multiple={true}
                          type="checkbox"
                          id={propCategory.value}
                          name="categories"
                          className="me-1"
                          value={propCategory.value}
                          label={propCategory.label}
                          onChange={handleCategoryChange}
                          checked={selectedCategories.includes(
                            propCategory.value
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Row>
            <Row>
              <Card>
                <Card.Body>
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Property Description
                  </h5>

                  <FormGroup
                    className="mb-3"
                    controlId="descriptionTitleValidation"
                  >
                    <FormLabel>Description Title</FormLabel>
                    <Form.Control
                      type="text"
                      name="description_title"
                      placeholder="Property Description Title"
                      required={true}
                      onChange={handleDescriptionTitleChange}
                      value={descriptionTitle}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter property description title.
                    </Form.Control.Feedback>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName border border-1"
                      editorClassName="editorClassName px-2"
                      name="description"
                      placeholder="Property Description"
                      editorStyle={{ minHeight: "100px" }}
                      //editorState={editorState}
                      //onChange={setEditorState}
                    />
                  </FormGroup>
                </Card.Body>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row>
          <Card>
            <Card.Body>
              <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                Property Images
              </h5>
              <FileUploader
                showPreview={true}
                uploadUrl="https://apivb.karakayasonmez.com/api/v1/files/uploadFile"
                uploadKey={imageUploadKey}
                onFileChange={handleImageChange}
                onImageSubmit={handleImageSubmit}
                initialFiles={initialFiles}
              />
            </Card.Body>
          </Card>
        </Row>

        <Row>
          <Col>
            <div className="text-center mb-3">
              <Button className="btn w-sm btn-info waves-effect me-1">
                Cancel
              </Button>
              <Button
                className="btn w-sm btn-success waves-effect waves-light me-1"
                type="submit"
              >
                Save
              </Button>
              <Button className="btn w-sm btn-danger waves-effect waves-light me-1">
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default PropertyEdit;
