import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { MethodValue } from "react-dropzone-uploader";
import { meApi } from "../modules/auth/core/_requests";

export interface FileType extends File {
  preview?: string;
  formattedSize?: string;
}

interface FileUploaderProps {
  onFileUpload?: (files: FileType[]) => void;
  showPreview?: boolean;
  uploadUrl?: string;
  uploadKey?: string;
  onFileChange?: (status: any, meta: any, files: any) => void;
  onImageSubmit?: (files: any) => void;
  initialFiles?: string[];
}

const FileUploader = (props: FileUploaderProps) => {
  const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);

  const [token] = useState<string | undefined>(() => {
    return localStorage.getItem("@auth/token") ?? "";
  });


  /**
   * Handled the accepted files and shows the preview
   */
  const handleAcceptedFiles = (files: FileType[]) => {
    var allFiles = files;

    if (props.showPreview) {
      (files || []).map((file) =>
        Object.assign(file, {
          preview:
            file["type"].split("/")[0] === "image"
              ? URL.createObjectURL(file)
              : null,
          formattedSize: formatBytes(file.size),
        })
      );
      allFiles = [...selectedFiles];
      allFiles.push(...files);
      setSelectedFiles(allFiles);
    }

    if (props.onFileUpload) props.onFileUpload(allFiles);
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  /*
   * Removes the selected file
   */
  const removeFile = (fileIndex: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(fileIndex, 1);
    setSelectedFiles(newFiles);
    if (props.onFileUpload) props.onFileUpload(newFiles);
  };

  /*const getUploadParams = ( ) => {
    return { url: props.uploadUrl || 'https://httpbin.org/post' };
  }*/

  const getUploadParams = ({ file, meta }: { file: File, meta: { name: string, type: string, size: number } }) => {
    const body = new FormData();
    body.append('file', file);
    body.append('key', props.uploadKey || '');
    const headers = {
      "Authorization": "Bearer " + (token || ""),
      "Access-Control-Allow-Credentials": "true"
    };
    const method: MethodValue = 'POST';
    return { url: props.uploadUrl || 'https://httpbin.org/post', body, headers, method };
  }

  const handleChangeStatus = ({ meta, file }: { meta: any, file: any }, status: any) => {
    //console.log(file.xhr);
    if(props.onFileChange) {
      props.onFileChange(status, meta, file);
    }
  };

  const handleSubmit = (files: any[], allFiles: any[]) => {
    const imageIds = files.map(f => ({ image: '' + JSON.parse(f.xhr.response).data.ID }));
    if(props.onImageSubmit) {
      props.onImageSubmit(imageIds);
    }
    //console.log(imageIds);
    //allFiles.forEach(f => f.remove())
  }


  return (
    <>
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      autoUpload={true}
      styles={{ dropzone: { minHeight: 200, maxHeight: 550 }, previewImage: { minHeight: 50, maxHeight: 100 } }}
      canRemove={true}
      />
    </>
  );
};

export default FileUploader;
